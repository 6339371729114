import BlockShowroomGrid from "./BlockShowroomGrid/BlockShowroomGrid";
import BlockShowroomFilter from "./BlockShowroomFilter/BlockShowroomFilter";
import BlockShowroomCalendar from "./BlockShowroomCalendar/BlockShowroomCalendar";
import Button from "../../../components/Button/Button";
import { PiTable } from "react-icons/pi";
import { IoCalendarOutline } from "react-icons/io5";
import { useEffect, useRef, useState } from "react";
import BlockShowroomFilterCalendar from "./BlockShowroomFilterCalendar/BlockShowroomFilterCalendar";
import "./blockShowroom.css";
import CreateBlockShowroomModal from "../../../components/Modals/CreateBlockShowroomModal/CreateBlockShowroomModal";
import { formatDate } from "../../../utils/formatDateTime";
import fetchClient from "../../../services/fetch";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import { getBlockedShowroomsList } from "../../../redux-sagas/actions";
import { useDispatch } from "react-redux";
import useGridFunctionalities from "../../../hooks/useGridFunctionalities";

const BlockShowroom = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isInitial = useRef(true);
	const [view, setView] = useState("grid");
	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const [selectedMultiple, setSelectedMultiple] = useState([]);
	const [rowSelectionModel, setRowSelectionModel] = useState([]);
	const [calendarSlot, setCalendarSlot] = useState(null);
	const [calendarShowroomName, setCalendarShowroomName] = useState("");
	const [showroomData, setShowroomData] = useState([]);
	const [mode, setMode] = useState("create");
	const [isDisabled, setIsDisabled] = useState(false);
	const {
		paginationModel,
		sort,
		handlePaginationChange,
		handleSortChange,
		getPageSkip,
		filters,
		handleFiltersChange,
		handleClearFilters,
		handleSearchFilters,
		resetPageToZero
	} = useGridFunctionalities();

	const isGridView = view === "grid";

	useEffect(() => {
		setIsDisabled(selectedMultiple.length > 1 && !isCreateModalOpen);
	}, [isCreateModalOpen, selectedMultiple]);

	const buildQuery = () => {
		let filterQuery = "";
		for (const key in filters) {
			filterQuery += `&${key}=${key.includes("Date") ? formatDate(filters[key]) : filters[key]}`;
		}

		return `?sort=${JSON.stringify(sort)}&page=${JSON.stringify({
			skip: getPageSkip(),
			take: paginationModel.pageSize
		})}${filterQuery}`;
	};

	useEffect(() => {
		const getData = () => dispatch(getBlockedShowroomsList(buildQuery()));

		if (!isInitial.current && isGridView) {
			const getDataWithBuffer = setTimeout(getData, 200);
			return () => clearTimeout(getDataWithBuffer);
		} else {
			isInitial.current = false;
			getData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, sort, paginationModel, view]);

	const handleChangeText = (e) => setCalendarShowroomName(e.target.value);
	const handleClearCalendarShowroomName = () => setCalendarShowroomName("");

	const toggleModal = () => setIsCreateModalOpen(!isCreateModalOpen);

	const openModal = (type) => {
		setMode(type);
		toggleModal();
	};

	const fetchShowroomData = (callback) =>
		fetchClient()
			.get("/commondata/showrooms")
			.then((res) => callback(res.data.data))
			.catch((err) => console.log(err));

	const closeModal = () => {
		toggleModal();
		if (["duplicate", "create", "edit"].includes(mode)) setSelectedMultiple([]);
	};

	const resetData = () => {
		setSelectedMultiple([]);
		setRowSelectionModel([]);
		setCalendarSlot(null);
	};

	return (
		<div className='cont block-showroom block-mod'>
			{isGridView && (
				<BlockShowroomFilter
					fetchShowroomData={fetchShowroomData}
					setShowroomName={setCalendarShowroomName}
					view={view}
					setShowroomData={setShowroomData}
					showroomData={showroomData}
					showroomName={calendarShowroomName}
					filters={filters}
					handleFiltersChange={handleFiltersChange}
					handleClearFilters={handleClearFilters}
					handleSearchFilters={handleSearchFilters}
					resetPageToZero={resetPageToZero}
				/>
			)}
			<div
				className={
					isGridView
						? "top d-flex justify-content-between align-items-center flex-wrap"
						: "top-calendar"
				}
			>
				<div className='views mb-lg-0 mb-3'>
					<span>{getTranslation("View", t)}:</span>
					<div className='icons'>
						<span
							onClick={() => {
								setView("grid");
								resetData();
							}}
							className={isGridView ? "selected" : ""}
						>
							<PiTable />
						</span>
						<span
							onClick={
								calendarShowroomName && isGridView
									? () => {
											setView("calendar");
											resetData();
									  }
									: undefined
							}
							className={
								"calendar-btn " +
								(!isGridView ? "selected" : "") +
								(!calendarShowroomName && isGridView ? " disabled" : "")
							}
						>
							<IoCalendarOutline />
						</span>
					</div>
				</div>
				<div className={isGridView ? "btns" : "d-none"}>
					<Button
						disabled={isDisabled}
						onClick={() => openModal("create")}
						type='create'
					>
						{getTranslation("Create BLock", t)}
					</Button>
					<Button
						disabled={!isDisabled}
						onClick={() => openModal("multiple")}
						className='ps-4'
						type='multiple'
					>
						{getTranslation("Multiple blocking", t)}
					</Button>
				</div>
			</div>
			{!isGridView && (
				<BlockShowroomFilterCalendar
					showroomName={calendarShowroomName}
					handleChange={handleChangeText}
					handleClear={handleClearCalendarShowroomName}
				/>
			)}
			{isGridView ? (
				<BlockShowroomGrid
					buildQuery={buildQuery}
					rowSelectionModel={rowSelectionModel}
					setRowSelectionModel={setRowSelectionModel}
					openModal={openModal}
					isDisabled={isDisabled}
					selectedMultiple={selectedMultiple}
					setSelectedMultiple={setSelectedMultiple}
					paginationModel={paginationModel}
					handlePaginationChange={handlePaginationChange}
					handleSortChange={handleSortChange}
				/>
			) : (
				<BlockShowroomCalendar
					isCreateModalOpen={isCreateModalOpen}
					openModal={openModal}
					calendarSlot={calendarSlot}
					setCalendarSlot={setCalendarSlot}
					showroomName={calendarShowroomName}
				/>
			)}
			{isCreateModalOpen && (
				<CreateBlockShowroomModal
					calendarSlot={calendarSlot}
					selectedMultiple={selectedMultiple}
					setSelectedMultiple={setSelectedMultiple}
					closeModal={closeModal}
					setRowSelectionModel={setRowSelectionModel}
					mode={mode}
					buildQuery={buildQuery}
					open={isCreateModalOpen}
					view={view}
				/>
			)}
		</div>
	);
};

export default BlockShowroom;
