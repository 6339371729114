import React, { useCallback, useEffect, useRef, useState } from "react";
import "./createDemoModals.css";
import { Dialog } from "@mui/material";
import Button from "../../Button/Button";
import InformationSection from "./InformationSection/InformationSection";
import UploadSection from "./UploadSection/UploadSection";
import DurationSection from "./DurationSection/DurationSection";
import StatusSection from "./StatusSection/StatusSection";
import fetchClient from "../../../services/fetch";
import { useDispatch } from "react-redux";
import { getDemoList, toggleNotification } from "../../../redux-sagas/actions";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const CreateDemoModals = ({ open, mode, toggleModal, selected, buildQuery }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const initialized = useRef(false);
	const shouldSubmit = useRef(false);
	const [errors, setErrors] = useState({});
	const [payload, setPayload] = useState({});
	const [dropdownData, setDropdownData] = useState({
		brands: [],
		models: [],
		variants: [],
		fueltypes: [],
		showrooms: [],
		defaultDurationMinutes: []
	});
	const [uploadImg, setUploadImg] = useState(null);
	const [isDrag, setIsDrag] = useState(false);
	const [error, setError] = useState(null);

	const initEdit = () =>
		fetchClient()
			.get(`/vehicles/${selected?.vehicleId}`)
			.then((res) => {
				const data = res.data.data;
				setPayload(data);
				setUploadImg((prevState) => ({
					...prevState,
					name: data.originalImageFilename
				}));
				initialized.current = true;
			})
			.catch((err) => console.log(err));

	const sendRequest = useCallback(
		(data) => {
			const form = new FormData();

			form.append("vin", data.vin);
			form.append("brandId", data.brandId);
			form.append("modelId", data.modelId);
			form.append("variantId", data.variantId);
			form.append("fuelTypeId", data.fuelTypeId);
			form.append("showroomId", data.showroomId);
			form.append("isActive", data.isActive ?? true);
			form.append("defaultDurationMinutes", data.defaultDurationMinutes);
			form.append("file", uploadImg);
			(mode === "create" ? fetchClient().post : fetchClient().put)(
				`/vehicles${mode === "edit" ? "/" + selected.vehicleId : ""}`,
				form,
				{
					headers: {
						"Content-Type": "multipart/form-data"
					}
				}
			)
				.then(() => {
					dispatch(getDemoList(buildQuery()));
					dispatch(
						toggleNotification({
							isOpen: true,
							type: "success",
							content1: getTranslation("The demo for the VIN", t),
							highlight1: mode === "create" ? payload.vin : selected.vin,
							content2: `${getTranslation("has been", t)} ${
								mode === "create" ? t("created_la") : t("edited_la")
							} ${getTranslation("successfully", t)}.`,
							highlight2: ""
						})
					);
				})
				.catch((err) =>
					dispatch(
						toggleNotification({
							isOpen: true,
							type: "error",
							content1: getTranslation(err.response.data.message, t),
							highlight1: "",
							content2: "",
							highlight2: ""
						})
					)
				);
		},
		[buildQuery, dispatch, mode, payload.vin, selected, t, uploadImg]
	);

	const sendData = useCallback(() => {
		sendRequest(payload);
		toggleModal();
	}, [payload, sendRequest, toggleModal]);

	useEffect(() => {
		if (mode !== "create") {
			getDropdownData("brands");
			getDropdownData("models");
			getDropdownData("variants");
			getDropdownData("fueltypes");
			getDropdownData("showrooms");
			getDropdownData("testdrivedurations");
		}
	}, []);

	useEffect(() => {
		if (
			mode === "edit" &&
			dropdownData.brands.length > 0 &&
			dropdownData.models.length > 0 &&
			dropdownData.variants.length > 0 &&
			dropdownData.fueltypes.length > 0 &&
			dropdownData.showrooms.length > 0 &&
			!initialized.current
		) {
			initEdit();
		}
	}, [dropdownData]);

	useEffect(() => {
		if (!Object.keys(errors).length && shouldSubmit.current) sendData();
	}, [errors, sendData]);

	const handleDropdownChange = (e) => {
		const { name, value } = e.target;
		setPayload({ ...payload, [name]: value });
	};

	const handleSwitchChange = (e) => {
		const { name, checked } = e.target;
		setPayload({ ...payload, [name]: checked });
	};

	const validate = () => {
		const err = {};
		if (!payload.brandId) err.brandId = true;
		if (!payload.modelId) err.modelId = true;
		if (!payload.variantId) err.variantId = true;
		if (!payload.fuelTypeId) err.fuelTypeId = true;
		if (!payload.showroomId) err.showroomId = true;
		if (!payload.vin) err.vin = true;
		if (!payload.defaultDurationMinutes) err.defaultDurationMinutes = true;
		if (!uploadImg) err.uploadImg = true;

		setErrors(err);
	};

	const handleSubmitClick = () => {
		validate();
		shouldSubmit.current = true;
	};

	const getDropdownData = useCallback(
		(name) => {
			const params = new URLSearchParams({
				...payload
			});

			let listAll = '';
			switch (name){
				case "brands":
				case "models":
				case "variants":
				case "showrooms":
				case "fueltypes":
					listAll = `listAll=true${params.size !== 0 ? '&' : ''}`; 
					break;
				default: break;
			}

			let url = `/commondata/${name}?${listAll}${params}`;
			fetchClient()
				.get(url)
				.then((res) => {
					if (name === "testdrivedurations") name = "defaultDurationMinutes";

					setDropdownData((prevState) => ({
						...prevState,
						[name]:
							name === "fueltypes"
								? res.data.data.map((item) => ({
										...item,
										name: getTranslation(item.name, t)
								  }))
								: res.data.data
					}));
				})
				.catch((err) => console.log(err));
		},
		[payload, t]
	);

	const handleImageSelect = (e) => {
		const files = e.target.files[0];
		if (files) {
			if (files?.size <= 3000000) {
				setUploadImg(files);
				setError(null);
			} else {
				const err = t("upload_invalid_file");
				setUploadImg(null);
				setError(err);
				return;
			}
		}
	};

	const handleDrag = (e) => {
		e.preventDefault();
		setIsDrag(true);
	};

	const handleDragLeave = (e) => {
		e.preventDefault();
		setIsDrag(false);
	};

	const handleDrop = (e) => {
		e.preventDefault();
		setIsDrag(false);
		const files = e.dataTransfer.files[0];
		if (files.size <= 3000000) {
			setUploadImg(files);
			setError(null);
		} else {
			const err = t("upload_invalid_file");
			setUploadImg(null);
			setError(err);
			return;
		}
	};

	const handleRemoveImage = () => {
		setUploadImg();
	};

	return (
		<div>
			<Dialog
				className='create-demo-modal'
				open={open}
				onClose={toggleModal}
			>
				<h3>{getTranslation(mode === "create" ? "Create" : "Edit", t)} Demo</h3>
				<InformationSection
					payload={payload}
					errors={errors}
					mode={mode}
					handleDropdownChange={handleDropdownChange}
					getDropdownData={getDropdownData}
					dropdownData={dropdownData}
				/>
				<UploadSection
					payload={payload}
					handleImageSelect={handleImageSelect}
					handleDrag={handleDrag}
					handleDragLeave={handleDragLeave}
					handleDrop={handleDrop}
					handleRemoveImage={handleRemoveImage}
					error={error}
					errors={errors}
					isDrag={isDrag}
					uploadImg={uploadImg}
					mode={mode}
				/>
				<DurationSection
					payload={payload}
					handleDropdownChange={handleDropdownChange}
					getDropdownData={getDropdownData}
					dropdownData={dropdownData}
					errors={errors}
				/>
				<StatusSection
					payload={payload}
					handleSwitchChange={handleSwitchChange}
				/>
				<div className='d-flex gap-4 align-self-center '>
					<Button
						type='secondary'
						children={getTranslation("Cancel", t)}
						onClick={toggleModal}
					/>
					<Button
						type='primary'
						children={getTranslation("Accept", t)}
						onClick={handleSubmitClick}
					/>
				</div>
			</Dialog>
		</div>
	);
};

export default CreateDemoModals;
