import GridActionsCell from "../components/CustomCells/GridActionsCell";
import GridSelectHeader from "../components/CustomCells/GridSelectHeader/GridSelectHeader";
import GridStatusCell from "../components/CustomCells/GridStatusCell";
import { getTranslation } from "./getTranslation";
import i18next from "i18next";

export const fetchShowroomColumns = (handleEdit, handleChangeStatus, handleDeleteClick) => {
	const columns = [
		{
			field: "isActive",
			headerName: "Status",
			width: 240,
			renderCell: (params) => (
				<GridStatusCell
					handleChangeStatus={handleChangeStatus}
					{...params}
				/>
			),
			sortable: false
		},
		{
			field: "showroomName",
			headerName: "Name",
			minWidth: 240,
			flex: 1
		},
		{
			field: "cityName",
			headerName: "City",
			minWidth: 240,
			flex: 1
		},
		{
			field: "address",
			headerName: "Address",
			minWidth: 240,
			flex: 1
		},
		{
			field: "actions",
			headerName: "Actions",
			minWidth: 110,
			sortable: false,
			renderCell: (params) => (
				<GridActionsCell
					{...params}
					handleEdit={handleEdit}
					handleDelete={handleDeleteClick}
				/>
			)
		}
	];

	return columns;
};

export const fetchBlockedShowroomColumns = (
	handleEdit,
	handleDuplicate,
	handleDelete,
	isDisabled
) => {
	const columns = [
		{
			field: "__check__",
			type: "checkboxSelection",
			sortable: false,
			filterable: false,
			disableColumnMenu: true,
			disableReorder: true,
			disableExport: true,
			renderHeader: (params) => <GridSelectHeader {...params} />
		},
		{
			field: "showroomName",
			headerName: "Name",
			minWidth: 223,
			flex: 1
		},
		{
			field: "blockDate",
			headerName: "Blocking date",
			minWidth: 223,
			flex: 1
		},
		{
			field: "blockTime",
			headerName: "Blocking time",
			minWidth: 223,
			flex: 1
		},
		{
			field: "reason",
			headerName: "Blocking reason",
			minWidth: 223,
			flex: 1,
			renderCell: (params) => (params.row.reason ? undefined : <span>-</span>)
		},
		{
			field: "actions",
			headerName: "Actions",
			minWidth: 130,
			sortable: false,
			renderCell: (params) => (
				<GridActionsCell
					{...params}
					type='block'
					handleEdit={handleEdit}
					handleDuplicate={handleDuplicate}
					handleDelete={handleDelete}
					disabledDelete={params.row.deletable ? isDisabled : !params.row.deletable}
					disabledDuplicate={isDisabled}
					disabledEdit={params.row.deletable ? isDisabled : !params.row.deletable}
				/>
			)
		}
	];

	return columns;
};

export const fetchDemoColumns = (handleEdit, handleDeleteClick) => {
	const columns = [
		{
			field: "brandName",
			headerName: "Brand",
			minWidth: 130,
			flex: 1
		},
		{
			field: "vin",
			headerName: "VIN",
			minWidth: 210,
			flex: 1
		},
		{
			field: "modelName",
			headerName: "Model",
			minWidth: 130,
			flex: 1
		},
		{
			field: "variantName",
			headerName: "Variant",
			minWidth: 180,
			flex: 1
		},
		{
			field: "cityName",
			headerName: "City",
			minWidth: 130,
			flex: 1
		},
		{
			field: "blocks",
			headerName: "Blocks",
			minWidth: 80,
			flex: 1
		},
		{
			field: "schedules",
			headerName: "Appointments",
			minWidth: 130,
			flex: 1
		},
		{
			field: "actions",
			headerName: "Actions",
			minWidth: 105,
			sortable: false,
			renderCell: (params) => (
				<GridActionsCell
					{...params}
					type='demo'
					handleEdit={handleEdit}
					handleDelete={handleDeleteClick}
				/>
			)
		}
	];
	return columns;
};

export const fetchBlockDemoColumns = (handleEdit, handleDuplicate, handleDelete, isDisabled) => {
	const columns = [
		{
			field: "__check__",
			type: "checkboxSelection",
			sortable: false,
			filterable: false,
			disableColumnMenu: true,
			disableReorder: true,
			disableExport: true,
			renderHeader: (params) => <GridSelectHeader {...params} />
		},
		{
			field: "brandName",
			headerName: "Brand",
			minWidth: 130,
			flex: 1
		},
		{
			field: "vin",
			headerName: "VIN",
			minWidth: 195,
			flex: 1
		},
		{
			field: "modelName",
			headerName: "Model",
			minWidth: 130,
			flex: 1
		},
		{
			field: "blockDate",
			headerName: "Blocking date",
			minWidth: 223,
			flex: 1
		},
		{
			field: "blockTime",
			headerName: "Blocking time",
			minWidth: 200,
			flex: 1
		},
		{
			field: "reason",
			headerName: "Reason",
			minWidth: 150,
			flex: 1,
			renderCell: (params) =>
				params.row.reason ? undefined : <span className='MuiDataGrid-cellContent'>-</span>
		},
		{
			field: "actions",
			headerName: "Actions",
			minWidth: 130,
			sortable: false,
			renderCell: (params) => (
				<GridActionsCell
					{...params}
					type='block'
					handleEdit={handleEdit}
					handleDuplicate={handleDuplicate}
					handleDelete={handleDelete}
					disabledDuplicate={isDisabled}
					disabledEdit={params.row.deletable ? isDisabled : !params.row.deletable}
					disabledDelete={params.row.deletable ? isDisabled : !params.row.deletable}
				/>
			)
		}
	];
	return columns;
};

export const fetchGeneralReportsColumns = () => [
	{
		field: "createdDateFormatted",
		headerName: "Creation date",
		minWidth: 170,
		flex: 1
	},
	{
		field: "createdTimeFormatted",
		headerName: "Creation time",
		minWidth: 170,
		flex: 1
	},
	{
		field: "createdByFullName",
		headerName: "Creator's name",
		minWidth: 170,
		flex: 1
	},
	{
		field: "createdByRoleName",
		headerName: "Creator's profile",
		minWidth: 170,
		flex: 1
	},
	{
		field: "createdAt",
		headerName: "Creation channel",
		minWidth: 170,
		flex: 1
	},
	{
		field: "bookingDateFormatted",
		headerName: "Appointment day",
		minWidth: 170,
		flex: 1
	},
	{
		field: "startTimeFormatted",
		headerName: "Appointment time",
		minWidth: 170,
		flex: 1
	},
	{
		field: "durationMinutes",
		headerName: "Appointment duration",
		minWidth: 160,
		flex: 1
	},
	{
		field: "initialStatus",
		headerName: "Initial status",
		valueFormatter: (params) => getTranslation(params.value, i18next.t),
		minWidth: 140,
		flex: 1,
		renderCell: (params) =>
			params.row.initialStatus ? undefined : <span className='MuiDataGrid-cellContent'>-</span>
	},
	{
		field: "cityName",
		headerName: "City",
		minWidth: 120,
		flex: 1
	},
	{
		field: "brandName",
		headerName: "Brand",
		minWidth: 120,
		flex: 1
	},
	{
		field: "showroomName",
		headerName: "Dealer name",
		minWidth: 210,
		flex: 1
	},
	{
		field: "address",
		headerName: getTranslation("Dealer address", i18next.t),
		minWidth: 300,
		flex: 1
	},
	{
		field: "clientFullName",
		headerName: "Customer name",
		minWidth: 180,
		flex: 1
	},
	{
		field: "email",
		headerName: "Customer email",
		minWidth: 220,
		flex: 1
	},
	// {
	// 	field: "postalCode",
	// 	headerName: "Postal code",
	// 	minWidth: 140,
	// 	flex: 1,
	// 	renderCell: (params) =>
	// 		params.row.postalCode ? undefined : <span className='MuiDataGrid-cellContent'>-</span>
	// },
	{
		field: "phoneNumber",
		headerName: "Contact number",
		minWidth: 160,
		flex: 1
	},
	{
		field: "documentTypeName",
		headerName: "Document type",
		valueFormatter: (params) => getTranslation(params.value, i18next.t),
		minWidth: 180,
		flex: 1
	},
	{
		field: "documentNumber",
		headerName: "Document number",
		minWidth: 190,
		flex: 1
	},
	{
		field: "vehicleFullName",
		headerName: "Selected vehicle",
		minWidth: 220,
		flex: 1
	},
	{
		field: "fuelTypeName",
		headerName: "Fuel type",
		valueFormatter: (params) => getTranslation(params.value, i18next.t),
		minWidth: 190,
		flex: 1
	},
	{
		field: "vin",
		headerName: "VIN",
		minWidth: 190,
		flex: 1
	},
	{
		field: "comments",
		headerName: "Comments",
		minWidth: 240,
		flex: 1,
		renderCell: (params) =>
			params.row.comments ? undefined : <span className='MuiDataGrid-cellContent'>-</span>
	},
	{
		field: "referenceNumber",
		headerName: "Booking ID",
		minWidth: 170,
		flex: 1
	},
	{
		field: "rescheduledCanceledAt",
		headerName: "Rescheduling or cancellation channel",
		minWidth: 320,
		flex: 1,
		renderCell: (params) =>
			params.row.rescheduledCanceledAt ? undefined : (
				<span className='MuiDataGrid-cellContent'>-</span>
			)
	},
	{
		field: "rescheduledCanceledUserName",
		headerName: "User name",
		minWidth: 170,
		flex: 1,
		renderCell: (params) =>
			params.row.rescheduledCanceledUserName ? undefined : (
				<span className='MuiDataGrid-cellContent'>-</span>
			)
	},
	{
		field: "rescheduledCanceledRoleName",
		headerName: "User profile",
		minWidth: 170,
		flex: 1,
		renderCell: (params) =>
			params.row.rescheduledCanceledRoleName ? undefined : (
				<span className='MuiDataGrid-cellContent'>-</span>
			)
	},
	{
		field: "dateTimeRescheduledCanceled",
		headerName: "Date and time of rescheduling or cancellation",
		minWidth: 370,
		flex: 1,
		renderCell: (params) =>
			params.row.dateTimeRescheduledCanceled ? undefined : (
				<span className='MuiDataGrid-cellContent'>-</span>
			)
	}
];

export const fetchShowroomLogColumns = (t) => [
	{
		field: "showroomName",
		headerName: getTranslation("Showroom Name", t),
		minWidth: 220,
		flex: 1
	},
	{
		field: "status",
		headerName: getTranslation("Status", t),
		minWidth: 140,
		flex: 1,
		renderCell: (params) =>
			params.row.isActive ? (
				<span className='MuiDataGrid-cellContent'>{getTranslation("Active", t)}</span>
			) : (
				<span className='MuiDataGrid-cellContent'>{getTranslation("Inactive", t)}</span>
			)
	},
	{
		field: "email",
		headerName: getTranslation("User email", t),
		minWidth: 250,
		flex: 1
	},
	{
		field: "roleName",
		headerName: getTranslation("User role", t),
		minWidth: 180,
		flex: 1
	},
	{
		field: "dateChangedFormatted",
		headerName: getTranslation("Date of change", t),
		minWidth: 180,
		flex: 1
	},
	{
		field: "timeChangedFormatted",
		headerName: getTranslation("Time of change", t),
		minWidth: 180,
		flex: 1
	}
];

export const fetchAppointmentLogColumns = () => [
	{
		field: "clientFullName",
		headerName: "Customer name",
		minWidth: 170,
		flex: 1
	},
	{
		field: "email",
		headerName: "Customer email",
		minWidth: 200,
		flex: 1
	},
	{
		field: "phoneNumber",
		headerName: "Contact number",
		minWidth: 160,
		flex: 1
	},
	{
		field: "brandName",
		headerName: "Brand",
		minWidth: 150,
		flex: 1
	},
	{
		field: "modelName",
		headerName: "Model",
		minWidth: 120,
		flex: 1
	},
	{
		field: "variantName",
		headerName: "Version",
		minWidth: 180,
		flex: 1
	},
	{
		field: "selectedVehicle",
		headerName: "Selected vehicle",
		minWidth: 220,
		flex: 1
	},
	{
		field: "createdDateFormatted",
		headerName: "Creation date",
		minWidth: 180,
		flex: 1
	},
	{
		field: "createdTimeFormatted",
		headerName: "Creation time",
		minWidth: 180,
		flex: 1
	},
	{
		field: "bookingDateTimeFormatted",
		headerName: "Date and time of appointment",
		minWidth: 210,
		flex: 1
	},
	{
		field: "channelCreated",
		headerName: "Creation channel",
		minWidth: 170,
		flex: 1
	},
	{
		field: "userNameCreated",
		headerName: "Creation user",
		minWidth: 180,
		flex: 1
	},
	{
		field: "userRoleCreated",
		headerName: "User role",
		minWidth: 170,
		flex: 1
	},
	{
		field: "status",
		headerName: "Appointment status",
		valueFormatter: (params) => getTranslation(params.value, i18next.t),
		minWidth: 150,
		flex: 1
	}
];

export const fetchBlockLogColumns = () => [
	{
		field: "brandName",
		headerName: "Brand",
		minWidth: 150,
		flex: 1
	},
	{
		field: "modelName",
		headerName: "Model",
		minWidth: 120,
		flex: 1
	},
	{
		field: "variantName",
		headerName: "Version",
		minWidth: 180,
		flex: 1
	},
	{
		field: "vehicleFullName",
		headerName: "Selected vehicle",
		minWidth: 220,
		flex: 1
	},
	{
		field: "createdDateFormatted",
		headerName: "Creation date",
		minWidth: 180,
		flex: 1
	},
	{
		field: "createdTimeFormatted",
		headerName: "Creation time",
		minWidth: 180,
		flex: 1
	},
	{
		field: "blockDateTimeFormatted",
		headerName: "Date and time of blocking",
		minWidth: 210,
		flex: 1
	},
	{
		field: "reason",
		headerName: "Reason for the blockage",
		valueFormatter: (params) => getTranslation(params.value, i18next.t),
		minWidth: 170,
		flex: 1
	},
	{
		field: "userNameCreated",
		headerName: "Creation user",
		minWidth: 180,
		flex: 1
	},
	{
		field: "roleNameCreated",
		headerName: "User role",
		minWidth: 200,
		flex: 1
	}
];

export const fetchAppointmentStatusLogColumns = () => [
	{
		field: "channel",
		headerName: "Rescheduling or cancellation channel",
		minWidth: 330,
		flex: 1
	},
	{
		field: "userName",
		headerName: "User name",
		minWidth: 170,
		flex: 1
	},
	{
		field: "roleName",
		headerName: "User profile",
		minWidth: 170,
		flex: 1
	},
	{
		field: "dateTimeRescheduledCanceled",
		headerName: "Date and time of rescheduling or cancellation",
		minWidth: 380,
		flex: 1
	}
];
