import { DataGrid, useGridApiRef, esES } from "@mui/x-data-grid";
import getUniqueValues from "../../../../utils/getUniqueArrayValues";
import { useDispatch, useSelector } from "react-redux";
import { toggleDeleteModal, toggleNotification } from "../../../../redux-sagas/actions";
import { fetchBlockedShowroomColumns } from "../../../../utils/fetchColumns";
import { getBlockedShowroomsList } from "../../../../redux-sagas/actions";
import "./blockShowroomGrid.css";
import fetchClient from "../../../../services/fetch";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const BlockShowroomGrid = ({
	openModal,
	isDisabled,
	selectedMultiple,
	setSelectedMultiple,
	rowSelectionModel,
	setRowSelectionModel,
	buildQuery,
	paginationModel,
	handlePaginationChange,
	handleSortChange
}) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const isLoading = useSelector((state) => state.isLoading);
	const apiRef = useGridApiRef();
	const blockedShowroomsInfo = useSelector((state) => state.blockedShowroomsList);

	const handleEditClick = (params) => {
		setSelectedMultiple([params]);
		openModal("edit");
	};

	const handleDuplicateClick = (params) => {
		setSelectedMultiple([params]);
		openModal("duplicate");
	};

	const handleDelete = (params) =>
		fetchClient()
			.delete(`/blockedshowrooms/${params.scheduleId}`)
			.then(() => {
				dispatch(getBlockedShowroomsList(buildQuery()));
				dispatch(
					toggleNotification({
						isOpen: true,
						type: "success",
						content1: getTranslation("The block has been successfully deleted.", t),
						highlight1: "",
						content2: "",
						highlight2: ""
					})
				);
			})
			.catch((err) => console.log(err));

	const handleDeleteClick = (params) => {
		dispatch(
			toggleDeleteModal({
				isOpen: true,
				title: getTranslation("Delete blocking", t),
				content1: t("sure_delete_block"),
				closeCallback: () => handleDelete(params)
			})
		);
	};

	const handleSelectionChange = (newRowSelectionModel) => {
		setRowSelectionModel(newRowSelectionModel);
		const prevSelectedIds = selectedMultiple.map((item) => item.showroomId).filter(getUniqueValues);

		const selectedIds = newRowSelectionModel
			.map((item) => item.split(";")[1])
			.filter(getUniqueValues);

		const selectedRows = newRowSelectionModel
			.map((item) => {
				const split = item.split(";");
				const row = { showroomId: split[1], showroomName: split[2] };

				return row;
			})
			.filter(getUniqueValues);

		let addedRows,
			removedIds = [];

		// if selectedIds has more elements, row(s) have been added
		if (selectedIds.length > prevSelectedIds.length) {
			// get the new rows from the selection model that is not on the previously selected rows
			addedRows = selectedRows.filter((item) => !prevSelectedIds.includes(item.showroomId));
			addedRows.forEach((item) => {
				setSelectedMultiple((prevSelectedMultiple) => prevSelectedMultiple.concat(item));
			});
		} else {
			// get the removed ids from selectedMultiple that is not on the newly selected ids
			removedIds = prevSelectedIds.filter((item) => !selectedIds.includes(item));
			removedIds.forEach((id) => {
				setSelectedMultiple((prevSelectedMultiple) =>
					prevSelectedMultiple.filter((item) => item.showroomId !== id)
				);
			});
		}
	};

	return (
		<DataGrid
			localeText={
				i18next.language === "es" ? esES.components.MuiDataGrid.defaultProps.localeText : undefined
			}
			getRowId={(row) => `${row.scheduleId};${row.showroomId};${row.showroomName}`}
			getRowHeight={() => "auto"}
			onSortModelChange={(newSortModel) => handleSortChange(newSortModel)}
			columns={fetchBlockedShowroomColumns(
				handleEditClick,
				handleDuplicateClick,
				handleDeleteClick,
				isDisabled
			).map((item) => ({
				...item,
				headerName: getTranslation(item.headerName, t)
			}))}
			apiRef={apiRef}
			className='block'
			loading={isLoading}
			rows={blockedShowroomsInfo.data.map((item) => ({
				...item,
				reason: getTranslation(item.reason, t)
			}))}
			rowCount={blockedShowroomsInfo.totalRecords || 0}
			disableColumnMenu
			paginationMode='server'
			paginationModel={paginationModel}
			onPaginationModelChange={(paginationModel) => handlePaginationChange(paginationModel)}
			onRowSelectionModelChange={(newRowSelectionModel, e) =>
				handleSelectionChange(newRowSelectionModel)
			}
			rowSelectionModel={rowSelectionModel}
			pageSizeOptions={[5, 10, 15, 20]}
			slotProps={{
				pagination: {
					labelRowsPerPage: getTranslation("Rows per page:", t)
				}
			}}
			checkboxSelection
			disableRowSelectionOnClick
			keepNonExistentRowsSelected
		/>
	);
};

export default BlockShowroomGrid;
