import React, { useEffect, useRef, useState } from "react";
import "./blockDemo.css";
import BlockDemoFilter from "./BlockDemoFilter/BlockDemoFilter";
import { PiTable } from "react-icons/pi";
import { IoCalendarOutline } from "react-icons/io5";
import Button from "../../../components/Button/Button";
import BlockDemoGrid from "./BlockDemoGrid/BlockDemoGrid";
import BlockDemoCalendar from "./BlockDemoCalendar/BlockDemoCalendar";
import CreateBlockDemoModal from "../../../components/Modals/CreateBlockDemoModal/CreateBlockDemoModal";
import BlockDemoFilterCalendar from "./BlockDemoFilterCalendar/BlockDemoFilterCalendar";
import { formatDate } from "../../../utils/formatDateTime";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getBlockDemoList } from "../../../redux-sagas/actions";
import useGridFunctionalities from "../../../hooks/useGridFunctionalities";

const BlockDemo = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isInitial = useRef(true);
	const [view, setView] = useState("grid");
	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const [selectedMultiple, setSelectedMultiple] = useState([]);
	const [rowSelectionModel, setRowSelectionModel] = useState([]);
	const [calendarSlot, setCalendarSlot] = useState(null);
	const [mode, setMode] = useState("create");
	const [isDisabled, setIsDisabled] = useState(false);
	const {
		paginationModel,
		sort,
		handlePaginationChange,
		handleSortChange,
		getPageSkip,
		filters,
		handleFiltersChange,
		handleClearFilters,
		handleSearchFilters,
		resetPageToZero
	} = useGridFunctionalities();

	const isGridView = view === "grid";

	const buildQuery = () => {
		let filterQuery = "";
		let filterDateQuery = "";
		for (const key in filters) {
			if (key === "vin") {
				filterQuery += JSON.stringify({
					field: key,
					condition: "contains",
					value: filters[key]
				});
			} else {
				filterDateQuery += `&${key}=${
					key.includes("Date") ? formatDate(filters[key]) : filters[key]
				}`;
			}
		}

		return `?filters=[${filterQuery}]&page=${JSON.stringify({
			skip: getPageSkip(),
			take: paginationModel.pageSize
		})}&sort=${JSON.stringify(sort)}&${filterDateQuery}`;
	};

	useEffect(() => {
		setIsDisabled(selectedMultiple.length > 1 && !isCreateModalOpen);
	}, [isCreateModalOpen, selectedMultiple]);

	useEffect(() => {
		const getData = () => dispatch(getBlockDemoList(buildQuery()));

		if (!isInitial.current && isGridView) {
			const getDataWithBuffer = setTimeout(getData, 200);
			return () => clearTimeout(getDataWithBuffer);
		} else {
			isInitial.current = false;
			getData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, sort, paginationModel, view]);

	const toggleModal = () => setIsCreateModalOpen(!isCreateModalOpen);

	const handleChangeCalendarFilter = (e) =>
		handleFiltersChange({ ...filters, vin: e.target.value });

	const handleClearDemoCalendarFilter = () => handleClearFilters({ ...filters, vin: "" });

	const openBlockModal = (type) => {
		setMode(type);
		toggleModal();
	};

	const closeBlockModal = (type) => {
		toggleModal();
		if (type === "accept") setSelectedMultiple([]);
	};

	const resetData = () => {
		setSelectedMultiple([]);
		setRowSelectionModel([]);
		setCalendarSlot(null);
	};

	return (
		<div className='cont block-demo block-mod'>
			{isGridView && (
				<BlockDemoFilter
					handleClearFilters={handleClearFilters}
					resetPageToZero={resetPageToZero}
					filters={filters}
					handleSearchFilters={handleSearchFilters}
					handleFiltersChange={handleFiltersChange}
				/>
			)}
			<div
				className={
					isGridView
						? "top d-flex justify-content-between align-items-center flex-wrap"
						: "top-calendar"
				}
			>
				<div className='views mb-lg-0 mb-3'>
					<span>{getTranslation("View", t)}: </span>
					<div className='icons'>
						<span
							onClick={() => {
								setView("grid");
								resetData();
							}}
							className={isGridView ? "selected" : ""}
						>
							<PiTable />
						</span>
						<span
							onClick={
								filters.vin && isGridView
									? () => {
											setView("calendar");
											resetData();
									  }
									: undefined
							}
							className={
								"calendar-btn " +
								(!isGridView ? "selected" : "") +
								(!filters.vin && isGridView ? " disabled" : "")
							}
						>
							<IoCalendarOutline />
						</span>
					</div>
				</div>
				<div className={isGridView ? "btns" : "d-none"}>
					<Button
						disabled={isDisabled}
						type='create'
						onClick={() => openBlockModal("create")}
					>
						{getTranslation("Create Block", t)}
					</Button>
					<Button
						disabled={!isDisabled}
						type='multiple'
						onClick={() => openBlockModal("multiple")}
						className='ps-4'
					>
						{getTranslation("Multiple blocking", t)}
					</Button>
				</div>
			</div>
			{view !== "grid" && (
				<BlockDemoFilterCalendar
					vin={filters.vin}
					handleChange={handleChangeCalendarFilter}
					handleClear={handleClearDemoCalendarFilter}
				/>
			)}
			{isGridView ? (
				<BlockDemoGrid
					buildQuery={buildQuery}
					rowSelectionModel={rowSelectionModel}
					setRowSelectionModel={setRowSelectionModel}
					openModal={openBlockModal}
					isDisabled={isDisabled}
					paginationModel={paginationModel}
					handlePaginationChange={handlePaginationChange}
					handleSortChange={handleSortChange}
					selectedMultiple={selectedMultiple}
					setSelectedMultiple={setSelectedMultiple}
				/>
			) : (
				<BlockDemoCalendar
					isCreateModalOpen={isCreateModalOpen}
					openModal={openBlockModal}
					calendarSlot={calendarSlot}
					setCalendarSlot={setCalendarSlot}
					vin={filters.vin}
				/>
			)}
			{isCreateModalOpen && (
				<CreateBlockDemoModal
					calendarSlot={calendarSlot}
					selectedMultiple={selectedMultiple}
					setSelectedMultiple={setSelectedMultiple}
					closeModal={closeBlockModal}
					setRowSelectionModel={setRowSelectionModel}
					mode={mode}
					buildQuery={buildQuery}
					open={isCreateModalOpen}
					view={view}
				/>
			)}
		</div>
	);
};

export default BlockDemo;
